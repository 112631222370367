import React from "react";
import {
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Media,
  Badge
} from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import axios from "axios";
import * as Icon from "react-feather";
import classnames from "classnames";
import Autocomplete from "../../../components/@vuexy/autoComplete/AutoCompleteComponent";
import { history } from "../../../history";
import { connect, Provider } from "react-redux";
import { logoutUser } from "../../../actions/authActions";
import SweetAlert from "react-bootstrap-sweetalert";

const UserDropdown = props => {
  return (
    <DropdownMenu right>
      <DropdownItem tag="a" href="#" onClick={_ => props.logoutUser()}>
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle">Log Out</span>
      </DropdownItem>
    </DropdownMenu>
  );
};

class NavbarUser extends React.PureComponent {
  constructor(props) {
    super(props);
  }
  state = {
    navbarSearch: false,
    suggestions: [],
    defaultAlert: false
  };

  componentDidMount() {
    axios.get("/api/main-search/data").then(({ data }) => {
      this.setState({ suggestions: data.searchResult });
    });
  }

  handleNavbarSearch = () => {
    this.setState({
      navbarSearch: !this.state.navbarSearch
    });
  };
  logoutAdmin = () => {
    this.props.logoutUser();
  };
  alertLogout() {
    this.setState({ defaultAlert: true });
  }
  handleAlert = (state, value) => {
    this.setState({ [state]: value });
  };

  render() {
    return (
      <div>
        <SweetAlert
          title="Are you sure?"
          warning
          show={this.state.defaultAlert}
          showCancel
          reverseButtons
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Logout"
          cancelBtnText="Cancel"
          onConfirm={() => {
            this.logoutAdmin();
          }}
          onCancel={() => {
            this.handleAlert("defaultAlert", false);
          }}
        >
          you want to logout
        </SweetAlert>

        <ul className="nav navbar-nav navbar-nav-user float-right">
          <UncontrolledDropdown
            tag="li"
            className="dropdown-notification nav-item"
          ></UncontrolledDropdown>
          <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
            <DropdownToggle tag="a" className="nav-link dropdown-user-link">
              <div className="user-nav d-sm-flex d-none">
                <span className="user-name text-bold-600">
                  {this.props.userName}
                </span>
                {/* <span className="user-status">Available</span> */}
              </div>
              <span data-tour="user">
                <img
                  src={this.props.userImg}
                  className="round"
                  height="40"
                  width="40"
                  alt="avatar"
                />
              </span>
            <DropdownMenu right>
              <DropdownItem
                tag="a"
                href="#"
                onClick={() => {
                  this.alertLogout();
                }}
              >
                <Icon.Power size={14} className="mr-50" />
                <span className="align-middle">Log Out</span>
              </DropdownItem>
              </DropdownMenu>
            </DropdownToggle>
          </UncontrolledDropdown>
        </ul>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    logoutuser: state.logoutUser
  };
};
const mapDispatchToProps = { logoutUser };

export default connect(mapStateToProps, mapDispatchToProps)(NavbarUser);
