import axios from "axios";
// import setAuthToken from "../utils/setAuthToken";
//import jwt_decode from "jwt-decode";

import { GET_ERRORS, SET_CURRENT_USER, USER_LOADING, LOGIN_FAIL } from "./types";

// Register User
export const registerUser = (userData, history) => dispatch => {
	axios
		.post("", userData)
		.then(res => history.push("/login"))
		.catch(err =>
			dispatch({
				type: GET_ERRORS,
				payload: err.response.data
			})
		);
};

// Login - get user token
export const loginUser = userData => dispatch => {
	axios.post(process.env.REACT_APP_BASE_URL_LOCAL + 'login', userData, {
		headers: {
			'device-type': 'WEB',
		}
	})
		.then(res => {
			// Save to localStorage
			const result = res;

			if (res.data.status_code == 400 || res.data.status == "401"){

				dispatch(loginFail(res.data.message))
			} else {
				// Set token to localStorage
				const token = res.data.data.oauth_token;
				localStorage.setItem("Oauth", token);
				// Set token to Auth header
				// setAuthToken(token);
				// Decode token to get user data
				const decoded = token;
				// Set current user
				dispatch(setCurrentUser(decoded));
			}

		})
		.catch(err =>
			console.log(err)
			// dispatch({
			//   type: GET_ERRORS,
			//   payload: err.response.data.data
			// })
		);
};

// Set logged in user
export const setCurrentUser = decoded => {
	return {
		type: SET_CURRENT_USER,
		payload: decoded
	};
};

// Set login error message
export const loginFail = message => {
	return {
		type: LOGIN_FAIL,
		payload: message
	};
};

// User loading
export const setUserLoading = () => {
	return {
		type: USER_LOADING
	};
};

// Log user out
export const logoutUser = () => dispatch => {

  // Remove token from local storage
  var authtoken = { authtoken:localStorage.getItem('Oauth')  };

  axios.post(process.env.REACT_APP_BASE_URL_LOCAL + 'removeauthtoken', authtoken, {
		headers: {
			'device-type': 'WEB',
		}
	});
  localStorage.removeItem("Oauth");
  dispatch(loginFail(''))
	// Remove auth header for future requests
	//setAuthToken(false);
	// Set current user to empty object {} which will set isAuthenticated to false
	dispatch(setCurrentUser({}));
};
