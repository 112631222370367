import React from "react"
import * as Icon from "react-feather"
const navigationConfig = [
  {
    id: "home",
    title: "Home",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/"
  },

  {
    id: "users",
    title: "Child Support",
    type: "collapse",
    icon: <Icon.User size={20} />,
    children: [
      {
        id: "edit",
        title: "Add Child Support",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/app/child/addpage"
      },
      {
        id: "list",
        title: "Child Support List",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/app/child/list"
      }


    ]
  },
  {
    id: "family",
    title: "Family Support",
    type: "collapse",
    icon: <Icon.Users size={20} />,
    children: [
      {
        id: "edit",
        title: "Add Family Support",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/app/family/addpage"
      },
      {
        id: "list",
        title: "Family Supports List",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/app/family/list"
      }


    ]
  },
  {
    id: "service",
    title: "Services",
    type: "collapse",
    icon: <Icon.Settings size={20} />,
    children: [
      {
        id: "edit",
        title: "Add Service",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/app/service/addpage"
      },
      {
        id: "list",
        title: "Services List",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/app/service/list"
      }


    ]
  }
]

export default navigationConfig
